<template>
	<div class="invite">
		<van-nav-bar
		class="nav_bar_color"
		title="试驾邀请设置"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11"
		placeholder 
		:border="false"
		>
	</van-nav-bar>
		<!-- 头部标题 -->
		<div class="invite_top">
			<span>试乘试驾服务项目</span>
			<span>将显示在试乘试驾邀请函中</span>
		</div>
		
		<!-- 列表 -->
		<div class="invite_list" v-for="v,index in invite_list" :key="index">
			<span>{{v.name}}</span>
			<img @click="delopen(v.id)" src="@/assets/drivroute/icon_invite_error.png" mode="">
		</div>
		
		<div class="invite_upload" @click="open">
			<img src="@/assets/drivroute/icon_invite_tj.png" mode="">
			<span class="invite_upload_click">点击添加服务项目</span>
			<span class="invite_upload_title">例如：预留停车位</span>
		</div>
		
		<!-- <uni-popup ref="popup" type="bottom">
		<div class="invite_upload_fixed">
			<input placeholder-class="placeholderinput" v-model="invite_name" @confirm="confirm" type="text" confirm-type="send" class="uni-input inputs" :focus="isinput" placeholder="请输入服务名称" />
		</div></uni-popup> -->
		
		<!-- <van-dialog :show-confirm-button="false" :show-cancel-button="false" v-model="showadd" show-cancel-button> -->
			<van-overlay :show="showadd" @click="showadd = false">
				<div class="invite_upload_fixed" @click.stop>
					<input class="inputs" placeholder-class="placeholderinput" v-model="invite_name" @keyup.enter="confirm" type="text" ref='input' confirm-type="send" :focus="isinput" placeholder="请输入服务名称" />
				</div>
			</van-overlay>
			
	<!-- </van-dialog> -->
		
		<!-- 确认删除弹框 -->
		<van-dialog @confirm="delConfirm" @cancel="delClose" v-model="showdel" title="删除项目" show-cancel-button>
				<div style="padding: 20px;">确定删除指定服务项目吗？</div>
		</van-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				showadd:false,
				showdel:false,
				isinput:false,
				invite_list:'',
				invite_name:'',
				// 删除id
				del_id:'',
			};
		},
		mounted(){
			this.get_invite_list()
		},
		methods:{
			async confirm(e){
				if(this.invite_name == ''){
					return
				}
				const {data:res} = await this.$http.post('/api/scsj/add_store_service',{name:this.invite_name})
				if(res.code == 1){
					this.$toast.success('新增成功')
				}else{
					this.$toast.fail('新增失败')
				}
				
				this.invite_name = ''
				this.showadd = false
				this.isinput = false
				this.get_invite_list()
			},
			// onClickLeft(){
			// 	this.$router.go(-1)
			// },
			// 删除弹框取消
			delClose(){
				this.showdel = false
			},
			// 删除弹框确定
			async delConfirm(){
				const {data:res} = await this.$http.post('/api/scsj/del_store_service',{id:this.del_id})
				if(res.code == 1){
					this.$toast.success('删除成功')
					this.get_invite_list()
					this.showdel = false
				}else{
					this.$toast.fail(res.msg)
				}
			},

			// 打开删除弹框
			delopen(Id){
				this.showdel = true
				this.del_id = Id
			},
			
			// 打开弹框
			open(){
				this.isinput = false
				this.showadd = true
				 setTimeout(()=>{
					this.$refs.input.focus();
				},100);
				
			},
			// 获取数据
			async get_invite_list(){
				const {data:res} = await this.$http.post('/api/scsj/get_store_service')
				if(res.code == 1){
					this.invite_list = res.data.res
				}
			},
		}
	}
</script>

<style lang="scss">
.invite{
	padding: 0 0 120px;
}



.invite_top{
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 40px;
	span{
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		font-weight: 400;
		color: rgba(236, 236, 236, 0.6);
		line-height: 40px;
	}
}

.invite_list{
	margin: 40px;
	background: #323232;

	padding: 36px 32px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	span{
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		font-weight: 400;
		color: #ECECEC;
		line-height: 40px;
	}
	img{
		height: 40px;
		width: 40px;
	}
}

.invite_upload{
	width: 336px;
	height: 254px;
	background: #323232;
	display: flex;
	flex-direction: column;
	margin: 80px auto 0;
	align-items: center;
	img{
		margin-top: 40px;
		width: 84px;
		height: 84px;
	}
	.invite_upload_click{
		font-size: 28px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		color: #ECECEC;
		margin-top: 15px;
	}
	.invite_upload_title{
		font-size: 20px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		color: #8E8E8E;
		line-height: 30px;
	}
}

.invite_upload_fixed{
	position: fixed;
	bottom: 0;
	padding: 30px 44px 50px;
	box-sizing: border-box;
	width: 100%;
	background: #D9DBDE;
	text-align: center;
	.inputs{
		font-size: 32px;
		font-family: HYQiHeiY3-55;
		font-weight: 400;
		height: 84px;
		width: 500px;
		text-align: center;
		color: #323232;
		line-height: 84px;
		border-radius: 42px 42px 42px 42px;
		padding: 0 54px;
		background-color: #ffffff;
	}
}
</style>
